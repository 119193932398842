<script setup lang="ts">
const isProd = import.meta.env.MODE === 'production'

const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)

const minOtcLtd = computed(() => {
  return USDollarFormatter(0).format(
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
      500
  )
})

const top5Otc = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.top5Otc ?? 500
  )
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const isMobile = useIsMobile()
</script>

<template>
  <div
    v-if="isPaidUser && isMobile"
    :class="['text-3xl sm:text-4xl text-center', 'font-semibold']"
  >
    Looks like
    <span class="text-[#FACC14]">
      you may qualify for {{ minOtcLtd }} per year
    </span>
    or more to spend on groceries!
  </div>
  <div v-else class="text-3xl sm:text-4xl text-center">
    Finish up with a licensed insurance agent.
  </div>

  <div class="relative">
    <img
      src="../../assets/yellow-arrow.gif"
      class="absolute right-0 -top-14 h-12 w-auto"
    />

    <ClickToCall :props="{ ctcLocation: 'last-step' }" v-slot="{ handleClick }">
      <Button
        :to="phone.uri"
        @click="handleClick"
        variant="undefined"
        :class="[
          'flex flex-col items-center justify-center',
          'animate-custom-ping w-full p-4 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full',
          'shadow-[0px_0px_0px_12px_#fff] p-4',
        ]"
      >
        <span
          class="flex text-start justify-center items-center h-full text-[#080B12] text-lg"
        >
          <Icon i-heroicons-solid:phone class="w-10 h-10 animate-shake mr-3" />
          <span class="flex flex-col items-start">
            <span class="font-bold"> Call {{ phone.formatted }} </span>
            <span class="font-normal">TTY 711</span>
          </span>
        </span>
      </Button>
    </ClickToCall>
  </div>

  <div
    v-if="isPaidUser && isMobile && isProd"
    class="text-lg sm:text-xl font-medium text-center"
  >
    Act quickly:
    <span class="p-2 bg-red text-white rounded">
      {{ timer }}
    </span>
  </div>
</template>

<style scoped>
.animate-custom-ping {
  animation: custom-ping 1.2s infinite;
}
@keyframes custom-ping {
  0% {
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
  }
  50% {
    box-shadow: 0 0 0 14px rgba(18, 59, 34, 0.7);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(11, 63, 30, 0);
  }
}
</style>
