<script setup lang="ts">
const props = defineProps<{
  items: {
    text: string
    icon: 'check' | 'x'
  }[]
  blackVersion?: boolean
}>()

const emit = defineEmits<{
  (e: 'done'): void
}>()

const showing = ref(0)

onMounted(async () => {
  await new Promise((resolve) => setTimeout(resolve, 750))
  for (let i = 0; i < props.items.length; i++) {
    showing.value++
    if (i < props.items.length - 1) {
      await new Promise((resolve) => setTimeout(resolve, 1500))
    }
  }
  await new Promise((resolve) => setTimeout(resolve, 750))
  emit('done')
})
</script>

<template>
  <div class="max-w-xs mx-auto space-y-3">
    <div
      v-for="(item, index) in items"
      :key="item.text"
      :class="[
        'flex items-center gap-4',
        blackVersion
          ? showing > index
            ? 'text-white'
            : 'text-gray-600'
          : showing > index
          ? 'text-black'
          : 'text-gray-400',
      ]"
    >
      <Spinner v-if="showing <= index" size="md" :white="blackVersion" />
      <div v-else-if="item.icon === 'x'" class="bg-red-200 rounded-full p-0.5">
        <Icon i-heroicons-solid:x class="text-red-700" />
      </div>
      <div v-else class="bg-[#1ca74f] rounded-full p-0.5">
        <Icon i-heroicons-solid:check class="text-white" />
      </div>

      <div class="flex-1 text-lg"> {{ item.text }} </div>
    </div>
  </div>
</template>
