<script setup lang="ts">
useHead({
  title: `Additional Benefits OTC - ${brand.name}`,
})

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.minOtcLtd) {
    return {
      code: state.code,
      name: state.name,
      minOtc: USDollarFormatter(0).format(state.minOtc),
      minOtcLtd: USDollarFormatter(0).format(state.minOtcLtd),
    }
  }
})

provide('state', state)
enterExperiment('additionalBenefitsOtc2024Image')

const isMobile = useIsMobile()
</script>

<template>
  <Layout header="simple3" footer="giveback" class="container">
    <template #banner>
      <Banner>
        On Medicare and Medicaid? *Available Medicare Advantage D-SNP
      </Banner>
    </template>

    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <h2
        :class="[
          !isMobile
            ? 'text-2xl text-center text-red font-medium font-dm'
            : 'text-xl sm:text-3xl font-bold text-center',
        ]"
      >
        Compare 2025 Medicare Advantage Plans with Over the Counter Allowance
        Benefits Allowance Benefits with plans from carriers like Humana and
        UnitedHealthcare®
      </h2>
      <div class="text-base text-gray-500 text-center">
        Not all plans offer all of these benefits. Benefits may vary by carrier
        and location. Limitations and exclusions may apply.
      </div>
      <div class="text-lg text-center">
        Shop Medicare Advantage plans that may include a spending allowance for
        common plan-approved OTC items.
      </div>
      <img
        src="../../assets/buyback-2024.jpeg"
        width="1200"
        height="627"
        class="w-[90%] sm:w-[400px] mx-auto"
        alt="Happy Senior couple dancing at the sunset in the beach"
      />
      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <Form
          id="otc2024"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicare' },
            { id: 'medicaid' },
            { id: 'otcallowance' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "med10", "m10", "boomer", "bridge"],
    "robots": "noindex",
    "smid": {
      "m10": "MULTI-PLAN_HLWEB1024272_M",
      "bh": "MULTI-PLAN_HLWEB0624191_M"
    }
  }
}
</route>
