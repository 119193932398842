<script setup lang="ts">
useHead({
  title: `Additional Social Security 2025 - ${brand.name}`,
})

const value = USDollarFormatter(0).format(2096)

const start = Date.now()
const now = useNow()
const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)
</script>

<template>
  <Layout
    :hideHeader="true"
    footer="2025"
    :class="'bg-black text-white min-h-[100vh]'"
  >
    <div class="container">
      <div class="max-w-3xl mx-auto space-y-4 py-6">
        <div class="text-xl sm:text-3xl font-bold text-center">
          Some Medicare members in {{ ip.lookup?.stateName || 'America' }}
          <span :class="'text-[#FACC14]'">
            may qualify for {{ value }}/yr or more reduction to Part B
          </span>
          premiums with some Medicare Advantage Plans.
        </div>

        <img
          src="../../assets/retirement-balance.png"
          alt="Two retirement balance, the left one with 'Before' and a list of $0 deposits and the second one with 'After' with several deposits of $174"
          width="1080"
          height="1080"
          class="w-[90%] sm:w-[400px] mx-auto"
        />

        <div :class="['text-3xl sm:text-4xl text-center', 'font-semibold']">
          Looks like
          <span class="text-[#FACC14]">
            you may qualify for {{ value }} per year
          </span>
          on Part B premum reductions.
        </div>

        <div class="relative">
          <img
            src="../../assets/yellow-arrow.gif"
            class="absolute right-0 -top-14 h-12 w-auto"
          />

          <ClickToCall
            :props="{ ctcLocation: 'last-step' }"
            v-slot="{ handleClick }"
          >
            <Button
              :to="phone.uri"
              @click="handleClick"
              variant="undefined"
              :class="[
                'flex flex-col items-center justify-center',
                'animate-custom-ping w-full p-4 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full',
                'shadow-[0px_0px_0px_12px_#fff] p-4',
              ]"
            >
              <span
                class="flex text-start justify-center items-center h-full text-[#080B12] text-lg"
              >
                <Icon
                  i-heroicons-solid:phone
                  class="w-10 h-10 animate-shake mr-3"
                />
                <span class="flex flex-col items-start">
                  <span class="font-bold"> CALL {{ phone.formatted }} </span>
                  <span class="font-normal">TTY 711</span>
                </span>
              </span>
            </Button>
          </ClickToCall>
        </div>

        <div class="text-lg sm:text-xl font-medium text-center">
          Act quickly:
          <span class="px-2 py-1 bg-red text-white rounded">
            {{ timer }}
          </span>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.animate-custom-ping {
  animation: custom-ping 1.2s infinite;
}
@keyframes custom-ping {
  0% {
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
  }
  50% {
    box-shadow: 0 0 0 14px rgba(18, 59, 34, 0.7);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(11, 63, 30, 0);
  }
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge"],
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0624201_M",
      "bridge": "MULTI-PLAN_HLWEB0624201_M"
    },
    "robots": "noindex"
  }
}
</route>
