<script setup lang="ts">
const peopleLooking = ref(100)

onMounted(() => {
  peopleLooking.value = Math.floor(Math.random() * 100 + 100)
})

const state = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)
})

const router = useRouter()
const route = useRoute()
const isMobile = useIsMobile()

const queryAfid = ref<string | null>()

onMounted(() => {
  queryAfid.value = getSessionEnhanced().query.afid
})

const isMobileOrTablet = ref(true)

onMounted(() => {
  isMobileOrTablet.value = window.innerWidth < 1024
})

const blackVersion = computed(() => {
  return route.query.black === 'true'
})

const destination = computed(() => {
  let domain = ''

  let path =
    (queryAfid.value === '811342' && !isMobileOrTablet.value) ||
    (queryAfid.value !== '811342' && !isMobile.value)
      ? '/ads'
      : isWorkingHours.value
      ? '/additional-benefits-grocery-2024/call-now'
      : '/additional-benefits-grocery-2024/clicks'

  const params = new URLSearchParams()

  if (path === '/ads' && blackVersion.value) {
    params.set('black', 'true')
  }

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    domain = `https://benefit-helpline.com`

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)
  }

  const qs = params.toString() && `?${params.toString()}`

  return domain + path + qs
})

const onFinish = () => {
  if (destination.value.startsWith('/')) {
    router.push(destination.value)
  } else {
    window.location.href = destination.value
  }
}

analytics.page({
  name: 'Searching for plans',
  category: 'LP',
  product: 'medicare',
})
</script>

<template>
  <Layout
    hide-header
    :footer="brand.id === 'bridge' ? 'bridge' : '2025'"
    :class="blackVersion ? 'bg-black text-white' : ''"
  >
    <div class="max-w-2xl mx-auto px-4 py-6 space-y-6">
      <div class="space-y-2">
        <h2 class="text-3xl text-center">
          Finding the
          <span
            :class="[
              'font-bold',
              blackVersion ? 'text-[#FACC14]' : 'text-[#2581FE]',
            ]"
          >
            Right Plan <span class="whitespace-nowrap">for You!</span>
          </span>
        </h2>
        <div class="text-lg text-center">
          Our licensed insurance agent is finding <b>the right plan for you</b>.
          <span class="whitespace-nowrap">Please wait.</span>
        </div>
      </div>

      <div class="relative">
        <img
          src="../../assets/find-right-plan-call-center-woman-black.png"
          width="512"
          height="512"
          class="w-[40%]"
        />
        <div class="absolute left-[30%] right-0 top-0 aspect-[219/71]">
          <div class="w-full h-full relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 219 71"
              :class="[
                'w-full h-full',
                blackVersion ? 'text-white' : 'text-black',
              ]"
            >
              <path
                d="M218 35.5C218 39.9517 215.217 44.3621 209.787 48.5245C204.371 52.6758 196.471 56.4572 186.62 59.651C166.928 66.035 139.662 70 109.5 70C76.3942 70 46.7864 65.2235 26.8523 57.7204C26.0762 57.4283 25.1007 57.3554 24.0754 57.3919C23.0298 57.4291 21.8372 57.5847 20.5745 57.8163C18.048 58.2796 15.1541 59.0633 12.4342 59.8949C9.70954 60.7279 7.13682 61.616 5.24678 62.2952C4.70839 62.4887 4.22499 62.6653 3.80879 62.8191C3.86109 62.5264 3.91785 62.203 3.9777 61.8536C4.25978 60.2068 4.61156 57.9758 4.88848 55.647C5.16468 53.3243 5.36987 50.8763 5.35112 48.8044C5.34176 47.7697 5.27648 46.8002 5.12658 45.9736C4.98171 45.1747 4.73695 44.3835 4.28088 43.8134C2.102 41.0897 1 38.3082 1 35.5C1 31.0483 3.78298 26.6379 9.2134 22.4755C14.6292 18.3242 22.529 14.5428 32.3802 11.349C52.0716 4.965 79.3377 1 109.5 1C139.662 1 166.928 4.965 186.62 11.349C196.471 14.5428 204.371 18.3242 209.787 22.4755C215.217 26.6379 218 31.0483 218 35.5Z"
                fill="current"
                fill-opacity="0.11"
                stroke="current"
                stroke-width="2"
              />
            </svg>
            <div
              class="absolute inset-0 flex items-center justify-center text-center px-[8%] py-[6%] text-xs sm:text-base"
            >
              <div>
                <span
                  :class="[
                    'font-semibold',
                    blackVersion ? 'text-[#FACC14]' : 'text-[#2581FE]',
                  ]"
                >
                  {{ peopleLooking }} people
                </span>
                <template v-if="state">
                  from
                  <span
                    :class="[
                      'font-semibold',
                      blackVersion ? 'text-[#FACC14]' : 'text-[#2581FE]',
                    ]"
                  >
                    {{ state.name }}
                  </span>
                </template>
                are currently searching for plans.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Checklist2
          :black-version="blackVersion"
          :items="[
            {
              text: 'Searching our plan database',
              icon: 'check',
            },
            {
              text: 'Identifying insurance carriers',
              icon: 'check',
            },
            {
              text: `Finding Medicare plans in ${state?.name || 'America'}`,
              icon: 'check',
            },
          ]"
          @done="onFinish()"
        />
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge", "m10"],
    "robots": "noindex"
  }
}
</route>
