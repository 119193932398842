<script setup lang="ts">
import { Ref } from 'vue'

const { value: learnMore } = useField('learnMore')
const state = inject('state') as Ref<any>
</script>

<template>
  <div class="text-2xl text-blue text-center font-medium">
    Do you want to learn more about Medicare Advantage plans with flexible OTC
    allowance benefits of
    {{ state?.minOtcLtd ?? '$500' }}/per year or more?
  </div>
  <div class="grid grid-cols-2 gap-4">
    <Button wide size="sm" type="submit" @click="learnMore = true">
      Yes
    </Button>
    <Button wide size="sm" type="submit" @click="learnMore = false">
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="learnMore = null"
    >
      Skip
    </Button>
  </div>
</template>
