<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'
import Button from '~/components/Button.vue'

const loading = ref(true)
const source = ref(0)

const isPaidUser = ref(true)
const isMobile = useIsMobile()

const router = useRouter()

const show = ref(false)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (!isPaidUser.value && import.meta.env.MODE === 'production') {
    router.push('/go-4-benefits')
    return
  }

  show.value = true
})

const stateConfig = computed(() => {
  const minOtcLtd =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  const minOtc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc ?? 0
  const top5Otc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.top5Otc ?? 500

  return {
    minOtcLtd: minOtcLtd,
    minOtc: minOtc,
    top5Otc: top5Otc,
  }
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  source.value = 0
  setTimeout(() => {
    loading.value = false
    source.value = query.ad_value
      ? Number(query.ad_value) || 0
      : stateConfig.value?.minOtcLtd
  }, 500)
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

const queryAfid = ref<string | null>()

onMounted(() => {
  queryAfid.value = getSessionEnhanced().query.afid
})

const isMobileOrTablet = ref(true)

onMounted(() => {
  isMobileOrTablet.value = window.innerWidth < 1024
})

const destination = computed(() => {
  let domain = ''

  const path =
    isWorkingHours.value && isMobile.value
      ? '/additional-benefits-grocery-2024/call-now'
      : '/additional-benefits-grocery-2024/clicks'

  const params = new URLSearchParams()

  params.set('black', 'true')

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    domain = 'https://benefit-helpline.com'

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)
  }

  const qs = params.toString() && `?${params.toString()}`

  return domain + path + qs
})

analytics.page({
  name: 'Get Yours CSNP spanish',
  category: 'LP',
  product: 'medicare',
})
</script>

<template>
  <Layout
    v-if="show"
    :hide-header="true"
    :footer="brand.id === 'bridge' ? 'bridge' : '2025'"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> ¿Nuevo en Medicare? ¿Tiene Medicaid? </Banner>
    </template>
    <div
      class="bg-black flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative"
    >
      <span
        class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
      >
        Ad
      </span>

      <div class="container md:max-w-1/2 max-w-full flex flex-col gap-4">
        <div
          class="text-white text-4xl md:text-5xl flex flex-col md:inline-block md:py-3 md:text-center"
        >
          ¿Ya tienes tu tarjeta de
          <span class="text-6xl md:text-7xl text-yellow">
            {{ USDollarFormatter(0).format(output) }} o más al año
          </span>
          para compras de artículos aprobados por el plan?
        </div>

        <div
          class="w-full grid grid-cols-2 gap-4 max-w-[calc(100%-10px)] ml-1 mt-4 flex-grow"
        >
          <Button
            variant="undefined"
            :to="destination"
            :class="[
              'button w-full h-12 bg-red-500 border-red-400 cursor-pointer select-none !rounded-full border-[1px]',
              'active:translate-y-2 active:[box-shadow:0_0px_0_0_#f87171,0_0px_0_0_#f8717141] active:border-b-[0px] [box-shadow:0_10px_0_0_#f87171,0_15px_0_0_#f8717141]',
              'transition-all duration-150 ',
            ]"
            @click="
              analytics.track('learnMoreClicked', {
                source: $route.fullPath,
                destination,
                button: 'no',
              })
            "
          >
            <span
              class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
            >
              No
            </span>
          </Button>
          <Button
            variant="undefined"
            :to="destination"
            :class="[
              'button w-full h-12 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full border-[1px]',
              'active:translate-y-2 active:[box-shadow:0_0px_0_0_#1ca54e,0_0px_0_0_#22c55e41] active:border-b-[0px] [box-shadow:0_10px_0_0_#1ca54e,0_15px_0_0_#22c55e41]',
              'transition-all duration-150 ',
            ]"
            @click="
              analytics.track('learnMoreClicked', {
                source: $route.fullPath,
                destination,
                button: 'yes',
              })
            "
          >
            <span
              class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
            >
              Sí
            </span>
          </Button>
        </div>

        <img src="../../assets/fridge.png" alt="A fridge full of food" />
      </div>
    </div>
    <div class="bg-black text-white">
      <div class="container space-y-2 py-2">
        <div class="text-sm text-center">
          ¿Tienes Medicare y Medicaid y una condición especial?
          <br />
          *Puedes disponer de Medicare Advantage C-SNP
        </div>

        <div class="text-center text-gray-400"> MULTI-PLAN_HLDAD1224317_M </div>
      </div>
    </div>
    <div
      v-if="brand.id === 'bridge'"
      class="container space-y-8 !max-w-3xl py-8"
    >
      <Button
        v-if="isMobile"
        variant="undefined"
        :to="destination"
        class="border-0"
      >
        <img
          src="../../assets/emily-grocery-1.gif"
          width="720"
          height="300"
          alt="Dos imágenes de refrigeradores una al lado de la otra, la primera está vacía y la de la derecha llena de comestibles"
        />
      </Button>
      <h1 class="text-4xl font-bold">
        ¡Adultos Mayores: No Se Pierdan Estos Increíbles Programas de Asistencia
        para Comestibles!
      </h1>
      <h2>
        Nutra Sus Años Dorados—¡Explore Programas Esenciales de Asistencia para
        Comestibles Solo Para Usted!
      </h2>
      <img
        src="../../assets/emily-grocery-2.png"
        width="716"
        height="464"
        alt="Emily en un estacionamiento sosteniendo bolsas de comestibles"
      />
      <p class="text-lg">
        ¿Cree que la asistencia para comestibles es solo para aquellos en
        extrema necesidad? ¡Piénselo de nuevo! Incluso si se está manejando
        bien, estos programas pueden marcar una gran diferencia, ayudándole a
        ahorrar dinero para otras necesidades esenciales mientras se asegura de
        tener alimentos nutritivos. La realidad es que usted ha trabajado duro
        toda su vida y merece disfrutar de sus años dorados sin preocuparse por
        los costos de los comestibles. Desde los beneficios de SNAP hasta Meals
        on Wheels, estos programas están diseñados para mantenerlo saludable,
        feliz y próspero, independientemente de su nivel de ingresos.
      </p>
      <Button
        v-if="isMobile"
        variant="undefined"
        :to="destination"
        class="border-0"
      >
        <img
          src="../../assets/emily-grocery-3.gif"
          width="1728"
          height="864"
          alt="Anuncio con la pregunta: ¿Ya reclamó su asignación para comestibles?"
        />
      </Button>
      <h3> 1. Programa de Asistencia Nutricional Suplementaria (SNAP) </h3>
      <p class="text-lg">
        SNAP es el programa de ayuda nutricional federal más grande, que ofrece
        a los adultos mayores elegibles un beneficio mensual para comprar
        comestibles. La cantidad del beneficio está determinada por sus
        ingresos, el tamaño de su hogar y otros factores. Puede usar estos
        beneficios para comprar productos frescos, carnes, productos lácteos y
        más en minoristas autorizados.
      </p>
      <h3> 2. Comidas sobre Ruedas (Meals on Wheels)</h3>
      <p class="text-lg">
        Meals on Wheels entrega comidas calientes y nutritivas directamente a su
        puerta, asegurándole obtener los nutrientes diarios necesarios. Este
        servicio es especialmente útil si tiene movilidad limitada o le resulta
        difícil cocinar. Muchos programas locales también brindan servicios
        adicionales, como controles de seguridad y visitas sociales. Obtenga más
        información sobre Meals on Wheels.
      </p>
      <Button
        v-if="isMobile"
        variant="undefined"
        :to="destination"
        class="border-0"
      >
        <img
          src="../../assets/emily-grocery-4.png"
          width="716"
          height="808"
          alt="Anuncio con la pregunta: ¿Ya reclamó su asignación para comestibles? e imagen de una tarjeta de asignación negra"
        />
      </Button>
      <h3> 3. El Programa de Asistencia Alimentaria de Emergencia (TEFAP)</h3>
      <p class="text-lg">
        TEFAP ofrece apoyo alimentario de emergencia a personas de bajos
        ingresos, incluidos los adultos mayores. A través de TEFAP, puede
        acceder a alimentos gratuitos como productos enlatados, pasta y otros
        productos básicos de la despensa distribuidos por los bancos de
        alimentos locales y las organizaciones comunitarias.
      </p>
      <h3>
        4. Programa de Nutrición del Mercado de Agricultores para Personas
        Mayores (SFMNP)
      </h3>
      <p class="text-lg">
        Este programa proporciona a las personas mayores elegibles cupones que
        se pueden usar para comprar frutas, verduras y hierbas frescas en los
        mercados de agricultores, puestos de carretera y ubicaciones de
        agricultura apoyada por la comunidad. Es una excelente manera de
        disfrutar de productos frescos de temporada mientras apoya a los
        agricultores locales.
      </p>
      <Button
        v-if="isMobile"
        variant="undefined"
        :to="destination"
        class="border-0"
      >
        <img
          src="../../assets/emily-grocery-5.png"
          width="716"
          height="938"
          alt="Anuncio para personas con medicare con la imagen de una tarjeta de asignación azul"
        />
      </Button>
      <h3>
        5. Programa de Alimentos Suplementarios de Productos Básicos (CSFP)
      </h3>
      <p class="text-lg">
        CSFP ofrece paquetes de alimentos mensuales a adultos mayores de bajos
        ingresos, llenos de artículos nutritivos como cereales, frutas y
        verduras enlatadas y proteínas. Estos paquetes están diseñados para
        complementar su dieta con nutrientes esenciales que de otra manera no
        estaría obteniendo.
      </p>
      <hr class="border-zinc-300" />
      <p class="text-lg">
        Estos programas tienen como objetivo ayudarle a mantener una dieta
        saludable y mejorar su bienestar general. No dude en solicitar o
        comunicarse con sus servicios comunitarios locales para obtener ayuda;
        ¡se lo ha ganado!
      </p>
      <Button variant="undefined" :to="destination" class="border-0">
        <img
          src="../../assets/emily-grocery-3.gif"
          width="1728"
          height="864"
          alt="Anuncio con la pregunta: ¿Ya reclamó su asignación para comestibles?"
        />
      </Button>
    </div>
  </Layout>
</template>

<style scoped>
/* Tailwind does not have built-in 3D click effect, so we add custom CSS for that */
button:active {
  transform: translateY(4px);
}

.custom-transition {
  transition: width 0.5s;
}

.animate-custom-ping {
  animation: custom-ping 1.2s infinite;
}

@keyframes custom-ping {
  0% {
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
  }

  50% {
    box-shadow: 0 0 0 14px rgba(18, 59, 34, 0.7);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(11, 63, 30, 0);
  }
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge"],
    "robots": "noindex"
  }
}
</route>
