<script setup lang="ts">
const router = useRouter()

// Seems that the error is related to this
// https://stackoverflow.com/questions/41045270/range-error-with-tolocalestring-with-maximumnumber-of-digits-0
const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

const stateConfig = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  const maxGiveback = state?.maxGiveback ?? 70
  const minGiveback = state?.minGiveback ?? 4

  return {
    maxGiveback: Math.round(maxGiveback * 12),
    minGiveback: Math.round(minGiveback * 12),
  }
})

const startingValue = ref(100)
const totalLoops = 5
const durationPerLoop = 1120

const isPaidUser = ref(true)

const currentAmount = ref(startingValue.value)
const maxAmountClasses = ref('text-6xl')

const isMobile = useIsMobile()

onMounted(async () => {
  let loopCount = 0

  isPaidUser.value = getSessionEnhanced().isPaidUser

  enterExperiment('buybackAd2Redirect')

  if (exp.value?.buybackAd2Redirect?.redirect) {
    router.replace('/get-yours-ss1')
    return
  }

  if (
    import.meta.env.MODE === 'production' &&
    (!isPaidUser.value || !isMobile.value)
  ) {
    router.push('/go-4-benefits')
    return
  }

  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })

  const max = stateConfig.value?.maxGiveback ?? 70
  const incrementPerLoop = parseInt(
    `${(max - startingValue.value) / totalLoops}`,
    10
  )

  currentAmount.value += incrementPerLoop
  loopCount++
  const interval = setInterval(() => {
    loopCount++
    if (loopCount < totalLoops) {
      currentAmount.value += incrementPerLoop
    } else {
      currentAmount.value = max
      clearInterval(interval)
      maxAmountClasses.value = 'text-6xl animate-shakeOnce'
    }
  }, durationPerLoop)
})

const destination = computed(() => {
  if (!isMobile.value) {
    return '/ads'
  }
  let value = isPaidUser.value
    ? '/additional-benefits-buyback-2024/call-now'
    : '/additional-benefits-buyback-2024'

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    const domain = 'https://benefit-helpline.com'
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    const qs = params.toString() && `?${params.toString()}`

    value = domain + value + qs
  }

  return value
})

analytics.page({
  name: 'Buyback Bridge 1',
  category: 'LP',
  product: 'medicare',
})
</script>
<template>
  <Layout
    :hide-header="true"
    footer="2024"
    class="flex flex-col min-h-[100vh] gap-6 md:gap-12 font-dm items-center relative bg-white overflow-x-hidden"
  >
    <span
      class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
    >
      Ad
    </span>
    <div
      class="absolute -top-[320px] left-0 bg-red w-[400px] h-[650px] rotate-70 z-10"
    >
    </div>
    <div class="absolute top-0 left-0 w-full text-white p-8 pr-12 z-20">
      <div :class="maxAmountClasses"
        >{{ USDollar.format(currentAmount) }}/YR</div
      >
      <div class="text-xl mb-4 pr-8">Or more of Part B Premium Reduction</div>
    </div>

    <div
      class="text-white absolute left-0 top-0 text-black min-h-screen flex p-4 flex-col gap-4 z-0 font-dm"
    >
      <div
        class="w-full mt-40 h-3/4 p-8 rounded-lg flex items-center justify-center"
      >
        <img
          src="../../assets/piggy-bank-2.gif"
          alt="Piggy Bank"
          class="w-full h-full z-0"
        />
      </div>
      <div
        class="text-sm text-[#123B8D] -mt-6 font-sans text-center"
        v-if="!isPaidUser"
      >
        with some Medicare Advantage Plans
      </div>

      <Button
        :to="destination"
        @click="
          analytics.track('learnMoreClicked', {
            source: $route.fullPath,
            destination,
          })
        "
      >
        Learn more
      </Button>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "m10", "boomer", "bridge"],
    "robots": "noindex",
    "benefits": ["giveback"]
  }
}
</route>
