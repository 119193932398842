<script setup lang="ts">
useHead({
  title: `Additional benefits grocery 2024 - ${brand.name}`,
})

const isProd = import.meta.env.MODE === 'production'

const isFromFacebookOrGoogle =
  query.utm_source?.toLowerCase() === 'facebook' ||
  query.utm_source?.toLowerCase() === 'google'

provide('isFromFacebookOrGoogle', isFromFacebookOrGoogle)

const isMounted = useMounted()

const isPaidUser = ref(true)
const isMobile = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
  isMobile.value = window.innerWidth < 640
})

const lowBid = ref(false)
provide('lowBid', lowBid)

const minOtc = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc
})

const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  )
})

const shouldSkipToClicks = ref(false)

const onStepSubmit = (step: Record<string, any>) => {
  if (step.values.haveMedicare !== null) {
    shouldSkipToClicks.value =
      step.values.haveMedicare === false || !isWorkingHours.value
  }
}

const route = useRoute()
const blackVersion = route.path.indexOf('call-now') >= 0
</script>

<template>
  <Layout
    :header="blackVersion ? 'simpleBlack' : 'simple3'"
    :hideHeader="isMobile"
    footer="grocery2024"
    :class="blackVersion ? 'bg-black text-white' : ''"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="container">
      <div class="max-w-3xl mx-auto space-y-4 py-6">
        <div class="text-xl sm:text-3xl font-bold text-center">
          <template v-if="!isMobile">
            Are you shopping for Medicare Insurance plans? A licensed insurance
            agent can check your eligibility.
          </template>
          <template v-else-if="!isProd">
            Have Medicare? Turning 65? Some Medicare members in
            {{ ip.lookup?.stateName || 'America' }} may qualify for
            <span :class="blackVersion ? 'text-[#FACC14]' : 'text-red'">
              {{ minOtc ? `$${minOtc}/yr or more` : 'an allowance' }}
              towards OTC products
            </span>
            w some Medicare Advantage Plans.
          </template>
          <template v-else-if="query.utm_medium?.toLowerCase() === 'aff'">
            Some Medicare members in
            {{ ip.lookup?.stateName || 'America' }}
            <span :class="blackVersion ? 'text-[#FACC14]' : 'text-red'">
              may qualify for ${{ minOtcLtd }}/yr
            </span>
            or more allowance to use towards groceries with some Medicare
            Advantage Plans.
          </template>
          <template
            v-else-if="isFromFacebookOrGoogle && isPaidUser && isMobile"
          >
            Some Medicare members in
            {{ ip.lookup?.stateName || 'America' }}
            <span :class="blackVersion ? 'text-[#FACC14]' : 'text-red'">
              may qualify for ${{ minOtcLtd }}/yr
            </span>
            or more allowance to use towards groceries with some Medicare
            Advantage Plans.
          </template>
          <template v-else>
            Some Medicare members in
            {{ ip.lookup?.stateName || 'America' }}
            <span :class="blackVersion ? 'text-[#FACC14]' : 'text-red'">
              may qualify for ${{ minOtcLtd }}/yr
            </span>
            or more allowance to use towards groceries with some Medicare
            Advantage Plans.
          </template>
        </div>

        <template v-if="isMounted">
          <img
            v-if="!isMobile"
            src="../../assets/helpline-seniors-hands-up.png"
            width="1254"
            height="836"
            class="w-[90%] sm:w-[400px] mx-auto"
            alt="Happy seniors in a room with their hands up"
          />
          <img
            v-else-if="
              query.utm_source?.toLowerCase() === 'google' ||
              query.utm_source?.toLowerCase() === 'facebook'
            "
            src="../../assets/fruits-black-card.png"
            width="1100"
            height="576"
            class="w-[90%] sm:w-[400px] mx-auto"
            alt="Fruits section in a supermarket and a hand holding a black allowance card"
          />
          <img
            v-else-if="query.utm_medium?.toLowerCase() === 'aff'"
            src="../../assets/envelope-advantage-card.png"
            width="656"
            height="499"
            class="w-[90%] sm:w-[400px] mx-auto"
            alt="An envelope with a blue Medicare Advantage card"
          />
          <img
            v-else
            src="../../assets/helpline-seniors-hands-up.png"
            width="504"
            height="331"
            class="w-[90%] sm:w-[400px] mx-auto"
            alt="Happy seniors in a room with their hands up"
          />
        </template>

        <UseForm v-slot="{ values }">
          <Form
            id="grocery2024"
            class="!max-w-3xl"
            scrollBehavior="auto"
            :steps="[
              { id: 'medicareab' },
              { id: 'medicaid', if: !shouldSkipToClicks },
              { id: 'us', if: !isProd && !shouldSkipToClicks },
              { id: 'wantGrocery', if: isPaidUser && !shouldSkipToClicks },
              { id: 'loading', if: !shouldSkipToClicks },
              {
                id: 'call',
                if: !shouldSkipToClicks && (isProd ? isMobile : true),
              },
              { id: 'clicks' },
              //if: isProd ? !isMobile : false
              { id: 'call-now', if: false },
            ]"
            product="medicare"
            @step-submit="onStepSubmit"
          />
        </UseForm>
      </div>
      <div v-if="!blackVersion" class="h-px bg-gray-200"></div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "med10", "m10", "boomer"],
    "smid": {
      "m10": "MULTI-PLAN_EHWEB082324_M",
      "bh": "MULTI-PLAN_EHWEB062302_M",
      "boomer": "MULTI-PLAN_EHWEB082331_M",
      "med10": "MULTI-PLAN_EHWEB092334_M"
    },
    "xsmid": {
      "m10": "MULTI-PLAN_HLWEB0624181_M",
      "bh": "MULTI-PLAN_HLWEB0624183_M",
      "boomer": "MULTI-PLAN_EHWEB082331_M",
      "med10": "MULTI-PLAN_EHWEB092334_M"
    },
    "robots": "noindex"
  }
}
</route>
